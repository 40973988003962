import React, {ReactNode} from "react";

import MarkerClusterGroup from "react-leaflet-cluster";
import {MapContainer, TileLayer, Marker, Popup, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Leaflet, {latLngBounds} from "leaflet";

import Avatar from "@components/atoms/avatar";

import ProfileUtil from "@utils/ProfileUtil";

import brokenImage from "@assets/images/svg/BrokenImage.svg";

import multiUserProfile from "@db/userData";
import {iMultiUserDetails} from "@customTypes/appDataTypes/UserTypes";

import "./Style.css";

function customMarkerIcon(svgTemplate: string) {
	return new Leaflet.DivIcon({
		className: "test",
		html: `<img class="marker-img" src=${svgTemplate} onerror="this.onerror=null; this.src='${brokenImage}'"></img>`,
	});
}

// generate markers for map from here
const MyMarkers = (data: iMultiUserDetails) => {
	return data.ids.map((id: string, index: number) => {
		return (
			<Marker
				key={index}
				icon={customMarkerIcon(data?.details[id]?.photoUrl)}
				position={{
					lat: data?.details[id]?.location?.lat,
					lng: data?.details[id]?.location?.lng,
				}}
			>
				<Popup>
					<div style={{display: "flex", flexDirection: "column", gap: 10}}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								gap: 10,
							}}
						>
							<Avatar
								width={35}
								height={35}
								src={data?.details[id]?.photoUrl}
								borderRadius={50}
								fallBackImg={brokenImage}
							/>

							<div>
								{ProfileUtil.getFullName(
									data?.details[id]?.firstName,
									data?.details[id]?.lastName,
								)}
							</div>
						</div>
						<div
							className="textDecor"
							style={{
								border: "none",
								cursor: "pointer",
								alignSelf: "flex-start",
								color: "#4a00e0",
							}}
							onClick={() => {
								window.open(`/member?id=${id}`);
							}}
						>
							View Profile →
						</div>
					</div>
				</Popup>
			</Marker>
		);
	});
};

const getGroupOfAvatarsWithBadgeOfCount = (
	count: number,
	groupOfAvatar: ReactNode,
) => {
	if (count > 3) {
		return `<div class="avatar-stack">${groupOfAvatar}<div class="marker-img-no"><span class="badge">${
			count - 3
		}+</span></div>
		</div>`;
	} else {
		return `<div class="avatar-stack">${groupOfAvatar}
			</div>`;
	}
};

// create marker group icon here
const createClusterCustomIcon = (
	cluster: Leaflet.MarkerCluster,
): Leaflet.DivIcon => {
	const clusterChildren = cluster.getAllChildMarkers();
	const clusterChildrenCount = clusterChildren.length;

	let groupOfAvatar = "";
	let maximumClustersToBeRendered = clusterChildrenCount;
	if (clusterChildrenCount > 3) {
		maximumClustersToBeRendered = 3;
	}

	for (
		let childMarkerIndex = 0;
		childMarkerIndex < maximumClustersToBeRendered;
		childMarkerIndex++
	) {
		const childMarker = clusterChildren[childMarkerIndex];
		const childMarkerDivIconOptions = childMarker?.options?.icon
			?.options as Leaflet.DivIconOptions;

		groupOfAvatar += `${childMarkerDivIconOptions.html}`;
	}

	return Leaflet.divIcon({
		html: `${getGroupOfAvatarsWithBadgeOfCount(
			clusterChildrenCount,
			groupOfAvatar,
		)}`,
		className: "custom-marker-cluster",
		iconSize: Leaflet.point(40, 40, true),
	});
};

function ChangeView({markers}: {markers: iMultiUserDetails}) {
	const map = useMap();
	map.attributionControl.setPrefix(false); // hide leaflet attribution
	map.setView({lng: 0, lat: 0});
	const markerBounds: Leaflet.LatLngBounds = latLngBounds([]);
	if (markers.ids && markers.ids.length > 0) {
		markers.ids.map((id: string) => {
			markerBounds.extend([
				markers.details[id].location.lat,
				markers.details[id].location.lng,
			]);
		});
		map.flyToBounds(markerBounds);
	}
	return null;
}
// Main Map wrapper
const MapWrapper = () => {
	return (
		<MapContainer
			className="main-map-wrapper"
			center={[0, 0]}
			zoom={2}
			maxZoom={10}
			minZoom={2}
			attributionControl={true} // show open street map attribution
		>
			<ChangeView markers={multiUserProfile} />
			<TileLayer
				attribution='<div><a href="https://www.openstreetmap.org/copyright">&copy; OpenStreetMap</a> <span>contributors</span>
				</div> '
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				className="left-attribution"
			/>

			<MarkerClusterGroup
				iconCreateFunction={createClusterCustomIcon}
				chunkedLoading
			>
				{MyMarkers(multiUserProfile)}
			</MarkerClusterGroup>
		</MapContainer>
	);
};

export default MapWrapper;
