import React from "react";

import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";

import WebVitalsUtil from "@utils/WebVitalsUtil";

import AppRootPage from "@pages/appRootPage/AppRootPage";

import "@fontsource/poppins"; // import font

import "@styles/css/Global.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	// you can see a rerender because of using strict mode, its for making debus easier.
	//  its not a bug. it will be not in prod -
	// - https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
	<React.StrictMode>
		<BrowserRouter>
			<AppRootPage />
		</BrowserRouter>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
WebVitalsUtil.reportWebVitals();
