import React from "react";

import "./Style.css";

const Fallback = ({
	header,
	subHeader,
	svgIcon,
}: {
	header: string;
	subHeader: string | null;
	svgIcon: React.ReactNode;
}) => {
	return (
		<div className="fallback">
			{svgIcon}
			<div className="fallback__header">{header}</div>
			<div className="fallback__subtitle">{subHeader}</div>
		</div>
	);
};
export default Fallback;
