import {useLocation} from "react-router-dom";

const useGetQueryParams = (id: string) => {
	const location = useLocation();

	const search = location.search;

	const params = new URLSearchParams(search);

	const result = params.get(id);

	return result;
};

export default useGetQueryParams;
