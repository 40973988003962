import React from "react";

import Header from "@components/molecules/header";
import Footer from "@components/molecules/footer";

import AppRoutes from "@navigation/AppRoutes";

import "./Style.css";

function AppRootPage() {
	return (
		<>
			<Header />
			<AppRoutes />
			<Footer />
		</>
	);
}

export default AppRootPage;
