import React from "react";

import "./Style.css";

const Chip = ({
	fontWeight,
	backgroundColor,
	textColor,
	borderColor,
	chipText,
}: {
	fontWeight: number;
	backgroundColor: string;
	textColor: string;
	borderColor: string;
	chipText: string;
}) => {
	return (
		<div
			className="chip"
			style={{
				fontWeight,
				backgroundColor,
				color: textColor,
				border: `1px solid ${borderColor}`,
			}}
		>
			{chipText}
		</div>
	);
};
export default Chip;
