import Colors from "@styles/Colors";

const getEmploymentStatus = (employmentStatus: string) => {
	switch (employmentStatus) {
		case "Active":
			return {
				flagText: `✅ ${employmentStatus}`,
				errorMessage: null,
				backgroundColor: Colors.ICE_COLD,
				textColor: Colors.SALEM,
				borderColor: Colors.SALEM,
			};
		case "Resigned":
			return {
				flagText: `❌ ${employmentStatus}`,
				errorMessage:
					"⚠️ This individual is no longer associated with MakerGram",
				backgroundColor: Colors.PASTEL_PINK,
				textColor: Colors.AMARANTH,
				borderColor: Colors.AMARANTH,
			};
		default:
			return {
				flagText: `❌ Unknown`,
				errorMessage: null,
				backgroundColor: Colors.PASTEL_PINK,
				textColor: Colors.AMARANTH,
				borderColor: Colors.AMARANTH,
			};
	}
};

export default getEmploymentStatus;
