/**
 * Use the below given website for generating color names:
 * https://chir.ag/projects/name-that-color
 */
const Colors = Object.freeze({
	WHITE: "#FFFFFF",
	BLACK: "#000000",

	PASTEL_PINK: "#fecdd3",
	AMARANTH: "#e11d48",

	ICE_COLD: "#bbf7d0",
	SALEM: "#16a34a",
});

export default Colors;
