import React from "react";

import "./Style.css";

const Button = ({
	svgIcon,
	onHandleClick,
}: {
	svgIcon: React.ReactNode;
	onHandleClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
	return (
		<>
			<button className="button" onClick={onHandleClick}>
				{svgIcon}
			</button>
		</>
	);
};
export default Button;
