import React from "react";

import {getCurrentVersion} from "@utils/AppUtil";

import platformConfig from "@db/platformConfig";

import "./Style.css";

const {platformName, platformUrl} = platformConfig;

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__content">
				<text className="footer__content__version">v{getCurrentVersion()}</text>
				<span>&nbsp;&nbsp;</span>
				<span>&copy; Powered by &nbsp;</span>
				<a href={platformUrl} target="_blank" rel="noopener noreferrer">
					{" "}
					{platformName}
				</a>
			</div>
		</footer>
	);
};
export default Footer;
