import React from "react";

import MapWrapper from "@components/molecules/mapWrapper/MapWrapper";

import "./Style.css";

const HomePage = () => {
	return (
		<main>
			<MapWrapper />
		</main>
	);
};

export default HomePage;
