import React from "react";

import {Route, Routes} from "react-router-dom";

import ResourceNotFoundPage from "@pages/resourceNotFoundPage/ResourceNotFoundPage";
import HomePage from "@pages/homePage/HomePage";
import MemberPage from "@pages/memberPage/MemberPage";

const AppRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path="/member" element={<MemberPage />} />
			<Route path="/*" element={<ResourceNotFoundPage />} />
			<Route path="/" element={<HomePage />} />
			<Route path="/home" element={<HomePage />} />
		</Routes>
	);
};

export default AppRoutes;
