import React, {useState, useEffect} from "react";

import {CSSTransition} from "react-transition-group";

import logo from "@assets/images/svg/BrandLogo.svg";
import {ReactComponent as Hamburger} from "@assets/images/svg/HamburgerMenu.svg";

import "./Style.css";

export default function Header() {
	const [isNavVisible, setNavVisibility] = useState(false);
	const [isSmallScreen, setIsSmallScreen] = useState(true);

	const mediaQuery: MediaQueryList = window.matchMedia("(max-width: 700px)");
	useEffect(() => {
		mediaQuery.addEventListener("change", handleMediaQueryChange);
		handleMediaQueryChange(mediaQuery);

		return () => {
			mediaQuery.removeEventListener("change", handleMediaQueryChange);
		};
	}, []);

	const handleMediaQueryChange = (
		mediaQuery: MediaQueryListEvent | MediaQueryList,
	) => {
		if (mediaQuery.matches) {
			setIsSmallScreen(true);
		} else {
			setIsSmallScreen(false);
		}
	};

	const toggleNav = () => {
		setNavVisibility(!isNavVisible);
	};

	return (
		<header className="Header">
			<img src={logo} className="Logo" alt="logo" />
			<CSSTransition
				in={!isSmallScreen || isNavVisible}
				timeout={350}
				classNames="NavAnimation"
				unmountOnExit
			>
				<nav className="Nav">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://makergram.com/"
					>
						🏡 Home
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://makergram.com/community/"
					>
						♥️ Community
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://makergram.com/blog/"
					>
						📔 Blog
					</a>
				</nav>
			</CSSTransition>
			<button
				onClick={() => {
					toggleNav();
				}}
				className="Burger"
			>
				<Hamburger />
			</button>
		</header>
	);
}
