import multiUserProfile from "@db/userData";
import {iUserProfileResponse} from "@customTypes/appDataTypes/UserTypes";
import {NullableString} from "@customTypes/CommonTypes";

const getProfile = (id: NullableString): iUserProfileResponse => {
	const userProfileResponse: iUserProfileResponse = {
		isFound: false,
		message: "Please wait data is fetching...",
		data: null,
	};

	if (id) {
		const userProfile = multiUserProfile.details[id];

		if (userProfile) {
			userProfileResponse.isFound = true;
			userProfileResponse.message = null;
			userProfileResponse.data = userProfile;
		} else {
			userProfileResponse.isFound = false;
			userProfileResponse.message = `Couldn't find a team member`;
		}
	} else {
		userProfileResponse.isFound = false;
		userProfileResponse.message = `Couldn't find a team member`;
	}

	return userProfileResponse;
};

export default getProfile;
