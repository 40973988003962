import React from "react";

import Lottie from "react-lottie";

import sad from "@assets/animations/lottie/sad.json";

import "./Style.css";
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: sad,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
const ResourceNotFoundPage: React.FC = () => {
	return (
		<div className="fallback">
			<Lottie options={defaultOptions} height={256} width={256} />
			<div className="fallback__header">Ooops!</div>
			<div className="fallback__subtitle">
				The page you&#39;re looking for is not found!
			</div>
		</div>
	);
};
export default ResourceNotFoundPage;
