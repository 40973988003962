import React from "react";

import Lottie from "react-lottie";

import GetProfile from "@services/user/GetProfile";
import getEmploymentStatus from "@services/user/GetEmploymentStatus";

import Fallback from "@components/molecules/fallback/Fallback";
import Button from "@components/atoms/button";
import Chip from "@components/atoms/chip";
import Avatar from "@components/atoms/avatar";

import ProfileUtil from "@utils/ProfileUtil";

import {ReactComponent as Twitter} from "@assets/images/svg/TwitterIcon.svg";
import {ReactComponent as Insta} from "@assets/images/svg/InstagramIcon.svg";
import {ReactComponent as Github} from "@assets/images/svg/GithubIcon.svg";
import {ReactComponent as LinkedIn} from "@assets/images/svg/LinkedInIcon.svg";
import animationData from "@assets/animations/lottie/TwinkleStar.json";
import brokenImage from "@assets/images/svg/BrokenImage.svg";
import NoAnimation from "@assets/animations/lottie/noEmoji.json";

import {NullableString} from "@customTypes/CommonTypes";
import useGetQueryParams from "@hooks/UseGetQueryParams";

import "./Style.css";

const defaultOptionsForNoAnimation = {
	loop: true,
	autoplay: true,
	animationData: NoAnimation,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

const renderEmploymentStatusMessage = (
	message: NullableString,
	backgroundColor: string,
	textColor: string,
	borderColor: string,
) => {
	if (message) {
		return (
			<Chip
				fontWeight={400}
				backgroundColor={backgroundColor}
				textColor={textColor}
				borderColor={borderColor}
				chipText={message}
			/>
		);
	}
	return null;
};

const renderEmploymentStatus = (id: NullableString) => {
	const {isFound, data} = GetProfile(id);

	if (isFound && data) {
		const {employmentStatus} = data;
		const {flagText, errorMessage, backgroundColor, borderColor, textColor} =
			getEmploymentStatus(employmentStatus);

		return (
			<>
				<Chip
					fontWeight={900}
					backgroundColor={backgroundColor}
					textColor={textColor}
					borderColor={borderColor}
					chipText={flagText}
				/>

				{renderEmploymentStatusMessage(
					errorMessage,
					backgroundColor,
					textColor,
					borderColor,
				)}
			</>
		);
	}

	return null;
};

const MemberPage: React.FC = () => {
	const id = useGetQueryParams("id");

	const {isFound, data, message} = GetProfile(id);

	if (isFound && data) {
		return (
			<>
				<div className="wrapper-bo">
					<div className="wrapper-top"></div>
					<div className="wrapper">
						<div className="avatar">
							<div className="lottie">
								<Lottie options={defaultOptions} height={100} width={100} />
							</div>
							<Avatar
								width={200}
								height={200}
								src={data.photoUrl}
								borderRadius={50}
								fallBackImg={brokenImage}
							/>
						</div>
						<div
							className="chip-container"
							style={{
								padding: "10px",
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
								flexDirection: "column",
								gap: 10,
							}}
						>
							{renderEmploymentStatus(id)}

							<div
								className="name"
								style={{
									textAlign: "center",
									fontSize: "calc(25px + 1vw)",
									fontWeight: 900,
									letterSpacing: "2px",
									wordBreak: "break-word",
									marginTop: "20px",
								}}
							>
								{ProfileUtil.getFullName(data.firstName, data.lastName)}
							</div>
							<p
								style={{
									textAlign: "center",
									marginTop: "7px",
									fontSize: "calc(15px + 0.5vw)",
									wordBreak: "break-all",
									color: "grey",
									textRendering: "optimizeLegibility",
								}}
							>
								{data.designation}
							</p>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									gap: 10,
									marginBottom: "50px",
								}}
							>
								<Button
									svgIcon={<LinkedIn />}
									onHandleClick={() => {
										const a = document.createElement("a");
										a.target = "_blank";
										a.href = data?.social?.linkedInUrl;
										a.click();
									}}
								/>
								<Button
									svgIcon={<Github />}
									onHandleClick={() => {
										const a = document.createElement("a");
										a.target = "_blank";
										a.href = data?.social?.gitHubUrl;
										a.click();
									}}
								/>
								<Button
									svgIcon={<Twitter />}
									onHandleClick={() => {
										const a = document.createElement("a");
										a.target = "_blank";
										a.href = data?.social?.twitterUrl;
										a.click();
									}}
								/>
								<Button
									svgIcon={<Insta />}
									onHandleClick={() => {
										const a = document.createElement("a");
										a.target = "_blank";
										a.href = data.social.instagramUrl;
										a.click();
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		const svgIcon = (
			<Lottie options={defaultOptionsForNoAnimation} height={230} width={230} />
		);

		return <Fallback header="Ooops!" subHeader={message} svgIcon={svgIcon} />;
	}
};

export default MemberPage;
