/**
 * Returns full name by combining firstName and lastName with a space
 * in between.
 *
 * @param firstName
 * @param lastName
 * @returns fullName
 */
const getFullName = (firstName: string, lastName: string) => {
	return `${firstName} ${lastName}`;
};

export default {getFullName};
