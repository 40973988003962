import React from "react";

import "./Style.css";

interface iImageState {
	src: string;
	isError: boolean;
	isLoad: boolean;
}

const Avatar = ({
	width,
	height,
	borderRadius,
	src,
	fallBackImg,
}: {
	width: number;
	height: number;
	borderRadius: number;
	src: string;
	fallBackImg: string;
}) => {
	const [imageState, setImageState] = React.useState<iImageState>({
		src: src,
		isError: false,
		isLoad: false,
	});
	return (
		<>
			<img
				className="avatar-imag"
				width={width}
				height={height}
				style={{borderRadius: `${borderRadius}%`, border: "8px solid white"}}
				src={imageState.src}
				onError={() => {
					if (!imageState.isError) {
						setImageState({
							src: fallBackImg,
							isError: true,
							isLoad: false,
						});
					}
				}}
			></img>
		</>
	);
};
export default Avatar;
